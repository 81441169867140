import { Box, Container, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "../svg/marker.svg";
import orangeIcon from "../svg/orange-marker.svg";
import redIcon from "../svg/red-marker.svg";
import iconShadow from "../svg/marker-shadow.svg";

const Dashboard = () => {
  interface DeviceLocationInfoLog {
    latitude: string | null;
    longitude: string | null;
    temperature: string;
    humidity: string;
    battery: string;
    createdAt: string;
  }

  interface DeviceLocationInfoEvent {
    id: string;
    title: string;
    description: string;
    type: string;
    resolved: boolean;
    resolvedAt: null;
    createdAt: string;
  }

  interface DeviceLocationInfo {
    identifier: number;
    name: string;
    type: string;
    isPower: boolean;
    isCheated: boolean;
    isBanned: boolean;
    log: DeviceLocationInfoLog[];
    event: DeviceLocationInfoEvent[];
  }

  interface DeviceData {
    location: DeviceLocationInfo[];
  }

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deviceData, setDeviceData] = useState<DeviceData | null>();
  let position: LatLngExpression = [13.7563, 100.5018];

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [28, 46],
    iconAnchor: [17, 46],
  });

  let OrangeIcon = L.icon({
    iconUrl: orangeIcon,
    shadowUrl: iconShadow,
    iconSize: [28, 46],
    iconAnchor: [17, 46],
  });

  let RedIcon = L.icon({
    iconUrl: redIcon,
    shadowUrl: iconShadow,
    iconSize: [28, 46],
    iconAnchor: [17, 46],
  });

  L.Marker.prototype.options.icon = DefaultIcon;
  L.Popup.prototype.options.offset = [-2, -33];

  const handleQuery = () => {
    const token = localStorage.getItem("token");
    const config = { headers: { Authorization: `Bearer ${token}` } };

    if (!token) {
      navigate("/");
    }

    axios
      .get(`${process.env.REACT_APP_API_LINK}/device/latest`, config)
      .then((response) => {
        setDeviceData(response.data);
      })
      .then(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((coords) => {
            const { latitude, longitude } = coords.coords;
            position = [latitude, longitude];
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.status === 401) {
          navigate("/pending");
        }
      });
  };

  useEffect(() => {
    handleQuery();
  }, []);

  const renderDeviceLocation = deviceData?.location.map(
    (loc: DeviceLocationInfo) => {
      if (!loc.log[0]?.latitude || !loc.log[0]?.longitude) {
        return null;
      }
      return (
        <Marker
          icon={
            loc.isCheated ? OrangeIcon : loc.isBanned ? RedIcon : DefaultIcon
          }
          key={loc.identifier}
          position={[
            parseFloat(loc.log[0].latitude),
            parseFloat(loc.log[0].longitude),
          ]}
        >
          <Popup>
            {loc.name} ({loc.type})
            <br />
            Temperature: {loc.log[0].temperature}°C
          </Popup>
        </Marker>
      );
    }
  );

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      bg="#ECEEF1"
    >
      <Box
        w="90%"
        maxW="800px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        h="50px"
        px="10px"
        mt="50px"
        bg="white"
      >
        <Text textColor="#2C91C2" fontWeight="bold" textAlign="center">
          OVERVIEW
        </Text>
      </Box>
      <Container
        w="100%"
        maxW="container.xl"
        mt="15px"
        maxH={"calc(100vh-5rem)"}
      >
        <MapContainer
          center={position}
          zoom={6}
          style={{ height: "100vh", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {renderDeviceLocation}
        </MapContainer>
      </Container>
    </Box>
  );
};

export default Dashboard;

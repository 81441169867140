import {
    Box,
    Flex,
    Select,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    Icon,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    FormControl,
    FormLabel,
    ModalCloseButton,
    ModalFooter,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuGroup,
} from '@chakra-ui/react';
import { Search2Icon, RepeatIcon } from '@chakra-ui/icons';
import { FiMoreVertical } from 'react-icons/fi';
import { IoCloseCircleSharp, IoCheckmarkCircleSharp } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ManageUser = () => {
    interface userData {
        users: usersInfo[]
    }

    interface usersInfo {
        id: string,
        email: string,
        username: string,
        role: string,
        updatedAt: string,
        createdAt: string
    }

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState<userData | null>(null);

    const promoteUser = (userId: string) => {
        const token = localStorage.getItem('token');
        const config = {headers: { Authorization: `Bearer ${token}` }};
        const body = { userId, role: 'ADMIN' };
        
        axios
            .post(`${process.env.REACT_APP_API_LINK}/config/role`, body, config)
            .then((response) => {
                alert('User promoted successfully.');
                handleQuery();
            });
    }

    const handleQuery = () => {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };

        if (!token) {
        navigate('/');
        }
        axios
            .get(`${process.env.REACT_APP_API_LINK}/config/user`, config)
            .then((response) => {
                setUserData(response.data);
                console.log(response.data);
            })
            .then(() => {
                setLoading(false);
            })
            .catch((err) => {
                if (err.status === 401) {
                navigate('/pending');
                }
            });
    }

    useEffect(() => {
        setLoading(true);
        handleQuery();
    }, []);

    const renderUserData = userData?.users.map(
        (user) => {
          return (
            <Tr key={user.id}>
                <Td>
                    <Menu>
                        <MenuButton as={Button}>
                        <Icon as={FiMoreVertical} />
                        </MenuButton>
                        <MenuList>
                        <MenuGroup title='View User Actions'>
                            <MenuItem
                            onClick={() => {promoteUser(user.id)}}
                            >
                            Promote User
                            </MenuItem>
                            {/*
                            <MenuItem
                            onClick={() => {}}
                            >
                            Placeholder 2
                            </MenuItem>
                            <MenuItem
                            onClick={() => {}}
                            >
                            Placeholder 3
                            </MenuItem>
                            */}
                        </MenuGroup>
                        </MenuList>
                    </Menu>
                </Td>
                <Td>{user.id}</Td>
                <Td>{user.email}</Td>
                <Td>{user.username}</Td>
                <Td>{user.role}</Td>
            </Tr>
          );
        }
    );

    return (
        <Box bg='gray.100' p={4} minH='100vh'>
            <Flex justifyContent='space-between' alignItems='center'>
                <Text fontSize='18pt' fontWeight='bold' ml='5px'>
                MANAGE USERS
                </Text>
            </Flex>
            <br />
            <Box p={6} bg='white' borderRadius='md' boxShadow='md'>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Action</Th>
                            <Th>User ID</Th>
                            <Th>E-mail</Th>
                            <Th>Username</Th>
                            <Th>Role</Th>
                        </Tr>
                    </Thead>
                    <Tbody>{renderUserData}</Tbody>
                </Table>
                {/*
                <Flex mt={4} justify='space-between' align='center'>
                    <Text>Rows per page:</Text>
                    <Select w='70px' size='sm' defaultValue='10'>
                        <option value='5'>5</option>
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                    </Select>
                    <Text>1-2 of 2</Text>
                </Flex>
                */}
            </Box>
            <Text align='center' color='gray.500' mt={10}>
                © 2024. All rights reserved.
            </Text>
        </Box>
    );
}

export default ManageUser
import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  Select,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  ButtonGroup,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
  HStack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import MarkerSVG from "../svg/marker.svg";
import RedMarkerSVG from "../svg/red-marker.svg";
import GreenMarkerSVG from "../svg/green-marker.svg";

const History = () => {
  type DeviceHistory = {
    id: string;
    deviceId: string;
    temperature: string;
    humidity: string;
    latitude: string;
    longitude: string;
    battery: string;
    createdAt: string;
  };

  type DeviceLog = {
    id: string;
    deviceId: string;
    temperature: string;
    humidity: string;
    latitude: string | null;
    longitude: string | null;
    battery: string;
    createdAt: string;
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deviceData, setDeviceData] = useState<any>(null);
  const [deviceHistoryLog, setDeviceHistoryLog] = useState<DeviceHistory[]>([]);
  const [deviceLog, setDeviceLog] = useState<DeviceLog[]>([]);
  const [selectedSerialNo, setSelectedSerialNo] = useState<string>("");
  const [coordinates, setCoordinates] = useState<any>([]);
  const [logPage, setLogPage] = useState(1);
  const [maxLogPage, setMaxLogPage] = useState(1);
  const [fromDate, setFromDate] = useState<Date>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState<Date>(new Date(Date.now()));

  let position: LatLngExpression = [13.7563, 100.5018];

  const handleQuery = () => {
    const token = localStorage.getItem("token");
    const config = { headers: { Authorization: `Bearer ${token}` } };

    if (!token) {
      navigate("/");
    }

    axios
      .get(`${process.env.REACT_APP_API_LINK}/device/latest`, config)
      .then((response) => {
        setDeviceData(response.data);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        if (err.status === 401) {
          navigate("/pending");
        }
      });
  };

  const handleHistoryQuery = (serialNo: string) => {
    const token = localStorage.getItem("token");
    const config = { headers: { Authorization: `Bearer ${token}` } };

    if (!token) {
      navigate("/");
    }

    setDeviceHistoryLog([]);
    setDeviceLog([]);
    setMaxLogPage(1);
    setLogPage(1);

    axios
      .get(
        `${
          process.env.REACT_APP_API_LINK
        }/device/history/${serialNo}?start=${fromDate.toISOString()}&end=${toDate.toISOString()}`,
        config
      )
      .then((response) => {
        const historyLog = response.data?.history?.history;
        const reversedHistoryLog = historyLog?.reverse();
        setDeviceHistoryLog(reversedHistoryLog);

        const log = response.data?.history?.log;
        setDeviceLog(log);

        const maxPage = Math.ceil(log.length / 20);
        setMaxLogPage(maxPage);

        const newCoordinates = historyLog?.map((data: any) => [
          parseFloat(data.latitude),
          parseFloat(data.longitude),
        ]);
        setCoordinates(newCoordinates);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        if (err.status === 401) {
          navigate("/pending");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    handleQuery();

    if (selectedSerialNo) {
      handleHistoryQuery(selectedSerialNo);
    }
  }, [selectedSerialNo, fromDate, toDate]);

  const deviceDataDropdown = deviceData?.location.map((info: any) => {
    return (
      <option key={info.identifier} value={info.identifier}>
        {info.name} ({info.type})
      </option>
    );
  });

  const blueMarkerIcon = new L.Icon({
    iconUrl: MarkerSVG,
    iconSize: [21, 45],
    iconAnchor: [10, 45],
    popupAnchor: [0, -45],
  });

  const redMarkerIcon = new L.Icon({
    iconUrl: RedMarkerSVG,
    iconSize: [30, 50],
    iconAnchor: [15, 50],
    popupAnchor: [0, -50],
  });

  const greenMarkerIcon = new L.Icon({
    iconUrl: GreenMarkerSVG,
    iconSize: [30, 50],
    iconAnchor: [15, 50],
    popupAnchor: [0, -50],
  });

  const markerMapping = deviceHistoryLog?.map((data: any, index: number) => {
    return (
      <Marker
        key={index}
        position={[parseFloat(data.latitude), parseFloat(data.longitude)]}
        icon={
          index === 0
            ? greenMarkerIcon
            : index === deviceHistoryLog.length - 1
            ? redMarkerIcon
            : blueMarkerIcon
        }
      >
        <Popup>
          {index === 0 ? (
            <>
              <strong>Starting Point</strong>
              <br />
            </>
          ) : index === deviceHistoryLog.length - 1 ? (
            <>
              <strong>Destination Point</strong>
              <br />
            </>
          ) : (
            <></>
          )}
          <strong>Temp:</strong> {data.temperature} &#8451;
          <br />
          <strong>Battery:</strong> {data.battery} v
          <br />
          <strong>Time:</strong> {new Date(data.createdAt).toLocaleString()}
          <br />
          <strong>Coordinates:</strong> {data.latitude}, {data.longitude}
        </Popup>
      </Marker>
    );
  });

  const polylineCoordinates: LatLngExpression[] = deviceHistoryLog?.map(
    (data) => [parseFloat(data.latitude), parseFloat(data.longitude)]
  );

  const logTable = deviceLog?.map((data, index) => {
    if (index < (logPage - 1) * 20 || index >= logPage * 20) {
      return null;
    }

    return (
      <Tr key={data.id}>
        <Td>{data.createdAt}</Td>
        <Td>{data.temperature}</Td>
        <Td>{data.battery}</Td>
        <Td>{data.latitude}</Td>
        <Td>{data.longitude}</Td>
      </Tr>
    );
  });

  const downloadCSV = () => {
    const csv = deviceLog?.map((data) => {
      return `${data.createdAt},${data.temperature},${data.battery},${data.latitude},${data.longitude}`;
    });

    const header = ["Time", "Temperature", "Battery", "Latitude", "Longitude"];
    csv?.unshift(header.join(","));
    const csvData = csv?.join("\n");

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    const date = new Date();
    const year = `${date.getFullYear()}`;
    const month =
      date.getUTCMonth() < 9
        ? `0${date.getUTCMonth() + 1}`
        : `${date.getUTCMonth() + 1}`;
    const day =
      date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : `${date.getUTCDate()}`;

    a.href = url;
    a.download = `${selectedSerialNo}-${year}${month}${day}.csv`;
    a.click();
  };

  return (
    <Box bg="gray.100" p={4} minH="100vh">
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="18pt" fontWeight="bold" ml="5px">
          DEVICE HISTORY
        </Text>
      </Flex>
      <br />
      <Box p={6} bg="white" borderRadius="md" boxShadow="md">
        <Text fontSize="14pt" fontWeight="bold">
          Select The Device
        </Text>
        <br />
        <Select
          value={selectedSerialNo}
          onChange={(e) => setSelectedSerialNo(e.target.value)}
        >
          <option value="" disabled hidden>
            Click here to select the device
          </option>
          {deviceDataDropdown}
        </Select>
        <Center>
          <Box mt={3}>
            <Text fontSize="14pt" fontWeight="bold">
              Select The Time Range
            </Text>
            <HStack>
              <Text>From: </Text>
              <Input
                placeholder="From"
                size="md"
                type="datetime-local"
                width={"max-content"}
                value={new Date(
                  fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000
                )
                  .toISOString()
                  .slice(0, 19)}
                onChange={(e) => setFromDate(new Date(e.target.value))}
              />
              <Text>To: </Text>
              <Input
                placeholder="To"
                size="md"
                type="datetime-local"
                width={"max-content"}
                value={new Date(
                  toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000
                )
                  .toISOString()
                  .slice(0, 19)}
                onChange={(e) => setToDate(new Date(e.target.value))}
              />
            </HStack>
          </Box>
        </Center>

        <Box mt={6}>
          <Container
            w="100%"
            maxW="container.xl"
            mt="15px"
            maxH={"calc(100vh-5rem)"}
          >
            <MapContainer
              center={position}
              zoom={6}
              style={{ height: "100vh", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {polylineCoordinates && (
                <Polyline positions={polylineCoordinates} color="blue" />
              )}
              {markerMapping ? markerMapping : ""}
            </MapContainer>
          </Container>
        </Box>
        <Container mt={6} w="100%" maxW="container.xl">
          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Time (UTC)</Th>
                  <Th>Temperature (&#8451;)</Th>
                  <Th>Battery (v)</Th>
                  <Th>Latitude</Th>
                  <Th>Longitude</Th>
                </Tr>
              </Thead>
              <Tbody>{logTable}</Tbody>
            </Table>
          </TableContainer>
          <Center mx="auto">
            <Stack direction="row" spacing={4} mt={2}>
              <Button
                leftIcon={<SlArrowLeft />}
                colorScheme="gray"
                variant="ghost"
                size="sm"
                onClick={() => setLogPage(logPage - 1)}
                isLoading={logPage === 1}
                spinner={<SlArrowLeft />}
              ></Button>
              <Input
                size="sm"
                value={logPage}
                variant="flushed"
                width={"max-content"}
                onChange={(e) => setLogPage(parseInt(e.target.value))}
                type="number"
              />
              <Text fontSize="2xl">/</Text>
              <Input
                size="sm"
                value={maxLogPage}
                variant="flushed"
                width={"max-content"}
                type="number"
                readOnly
              />
              <Button
                rightIcon={<SlArrowRight />}
                colorScheme="gray"
                variant="ghost"
                size="sm"
                onClick={() => setLogPage(logPage + 1)}
                isLoading={logPage === maxLogPage}
                spinner={<SlArrowRight />}
              ></Button>
            </Stack>
          </Center>
          <Flex>
            <Button
              ml="auto"
              variant="ghost"
              size="sm"
              colorScheme="cyan"
              onClick={downloadCSV}
            >
              Download CSV
            </Button>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default History;

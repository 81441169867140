import { Icon, Box, Flex, Link as ChakraLink, IconButton, Text, useDisclosure, Drawer, DrawerContent, VStack, CloseButton} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink } from 'react-router-dom';
import { MdDashboard, MdDirectionsCar, MdSwitchAccount, MdHistory } from 'react-icons/md';

function SidebarContent({ onClose }: { onClose: () => void }) {
return (
    <VStack align='start' bg='white' p={4} h='100vh' w='250px' spacing={6}>
        <CloseButton onClick={onClose} alignSelf='flex-end'/>
        <ChakraLink as={ReactRouterLink} px={2} py={1} rounded='md' to='/dashboard' _hover={{textDecoration: 'none'}}>
            <Box px={2} py={1} rounded='md' _hover={{ background: '#E0F2F8', color: '#2C91C2' }} transition='background 0.2s ease'>
                <Icon as={MdDashboard} fontSize='lg'/> Dashboard
            </Box>
        </ChakraLink>
        <ChakraLink as={ReactRouterLink} px={2} py={1} rounded='md' to='/device' _hover={{textDecoration: 'none'}}>
            <Box px={2} py={1} rounded='md' _hover={{ background: '#E0F2F8', color: '#2C91C2' }} transition='background 0.2s ease'>
                <Icon as={MdDirectionsCar} fontSize='lg'/> Device
            </Box>
        </ChakraLink>
        <ChakraLink as={ReactRouterLink} px={2} py={1} rounded='md' to='/history' _hover={{textDecoration: 'none'}}>
            <Box px={2} py={1} rounded='md' _hover={{ background: '#E0F2F8', color: '#2C91C2' }} transition='background 0.2s ease'>
                <Icon as={MdHistory} fontSize='lg'/> Device History
            </Box>
        </ChakraLink>
        <ChakraLink as={ReactRouterLink} px={2} py={1} rounded='md' to='/manageuser' _hover={{textDecoration: 'none'}}>
            <Box px={2} py={1} rounded='md' _hover={{ background: '#E0F2F8', color: '#2C91C2' }} transition='background 0.2s ease'>
                <Icon as={MdSwitchAccount} fontSize='lg'/> Manage Users
            </Box>
        </ChakraLink>
    </VStack>
);
}

const DashboardNavbar = () =>
{
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box px={4}>
        <Flex h={16} alignItems='center' justifyContent='space-between'>
            <IconButton size='md' bg='transparent' icon={isOpen ? <CloseIcon /> : <HamburgerIcon />} aria-label='Toggle Sidebar' onClick={isOpen ? onClose : onOpen}/>
            <Text fontSize='lg' fontWeight='bold'>
                Admin Dashboard
            </Text>
        </Flex>

        <Drawer placement='left' onClose={onClose} isOpen={isOpen}>
            <DrawerContent>
                <SidebarContent onClose={onClose} />
            </DrawerContent>
        </Drawer>
        </Box>
    );
}
  
export default DashboardNavbar;  